<script lang="ts" setup>
import logoUrl from '@/assets/images/logo/logo_okaneko_vertical.svg?url';

const FINANCIAL_SERVICE_REPORT_URL =
  'https://public-docs.400f.jp/reports/kinsa_report.pdf';
</script>

<template>
  <div :class="$style.wrapper">
    <footer :class="$style.footer">
      <img :src="logoUrl" alt="オカネコ" :class="$style.footer__logo" />
      <div :class="[$style.footer__body, $style.body]">
        <div :class="[$style.body__links, $style.links]">
          <section :class="[$style.links__section, $style.section]">
            <h3 :class="$style.section__title">サービスについて</h3>
            <ul :class="[$style.section__list, $style.list]">
              <li :class="$style.list__item">
                <NuxtLink
                  href="https://400f.jp/about#company"
                  :class="$style.list__link"
                  target="_blank"
                  >会社概要</NuxtLink
                >
              </li>
              <li :class="$style.list__item">
                <NuxtLink
                  :to="{ path: '/policies/tos' }"
                  :class="$style.list__link"
                  >利用規約</NuxtLink
                >
              </li>
              <li :class="$style.list__item">
                <NuxtLink
                  :to="{ path: '/policies/privacy' }"
                  :class="$style.list__link"
                  >プライバシーポリシー</NuxtLink
                >
              </li>
              <li :class="$style.list__item">
                <NuxtLink
                  :to="{ path: '/policies/cookiepolicycmp' }"
                  :class="$style.list__link"
                  >利用者情報の外部送信について</NuxtLink
                >
              </li>
              <li :class="$style.list__item">
                <NuxtLink
                  :to="{ path: '/policies/supported_versions' }"
                  :class="$style.list__link"
                  >推奨環境</NuxtLink
                >
              </li>
              <li :class="$style.list__item">
                <NuxtLink :to="{ path: '/faq' }" :class="$style.list__link"
                  >よくあるご質問</NuxtLink
                >
              </li>
              <li :class="$style.list__item">
                <NuxtLink :to="{ path: '/contact' }" :class="$style.list__link"
                  >お問い合わせ</NuxtLink
                >
              </li>
            </ul>
          </section>
          <section :class="[$style.links__section, $style.section]">
            <h3 :class="$style.section__title">金融商品販売業について</h3>
            <ul :class="[$style.section__list, $style.list]">
              <li :class="$style.list__item">
                <a
                  :href="FINANCIAL_SERVICE_REPORT_URL"
                  target="_blank"
                  :class="$style.list__link"
                  >金融サービス仲介業に関する報告書
                </a>
              </li>
              <li :class="$style.list__item">
                <NuxtLink
                  :to="{ path: '/lp/fiduciaryduty' }"
                  :class="$style.list__link"
                  >フィデューシャリーデューティー宣言
                </NuxtLink>
              </li>
              <li :class="$style.list__item">
                <NuxtLink
                  :to="{ path: '/policies/intermediary' }"
                  :class="$style.list__link"
                  >金融サービス仲介業に係る明示事項</NuxtLink
                >
              </li>
              <li :class="$style.list__item">
                <NuxtLink
                  :to="{ path: '/policies/dispute' }"
                  :class="$style.list__link"
                  >苦情処理・紛争解決に係る体制
                </NuxtLink>
              </li>
              <li :class="$style.list__item">
                <NuxtLink
                  :to="{ path: '/policies/entruster' }"
                  :class="$style.list__link"
                >
                  委託元金融機関に関して
                </NuxtLink>
              </li>
              <li :class="$style.list__item">
                <NuxtLink
                  :to="{ path: '/policies/solicitation' }"
                  :class="$style.list__link"
                  >勧誘方針</NuxtLink
                >
              </li>
            </ul>
          </section>
          <!-- TODO: オカネコ保険比較が出たらここにリンクを追加する -->
          <section :class="[$style.links__section, $style.section]">
            <h3 :class="$style.section__title">その他</h3>
            <ul :class="[$style.section__list, $style.list]">
              <li :class="$style.list__item">
                <a
                  href="https://okane-kenko.jp/media/category/mystory/"
                  :class="$style.list__link"
                  >MyStory</a
                >
              </li>
              <li :class="$style.list__item">
                <NuxtLink
                  :to="{ path: '/policies/antisocial' }"
                  :class="$style.list__link"
                  >反社会的勢力に対する基本方針</NuxtLink
                >
              </li>
              <li :class="$style.list__item">
                <NuxtLink
                  :to="{ path: '/policies/attention' }"
                  :class="$style.list__link"
                  >注意喚起情報</NuxtLink
                >
              </li>
            </ul>
          </section>
          <section :class="[$style.links__actions, $style.actions]">
            <h3 :class="$style.actions__title">さまざまな取り組み</h3>
            <div
              :class="[$style.actions__certifications, $style.certifications]"
            >
              <div :class="[$style.certifications__isms, $style.isms]">
                <img
                  src="./ISMS.png"
                  alt="ISMSロゴ"
                  loading="lazy"
                  width="462"
                  height="174"
                  :class="$style.isms__image"
                />
                <p :class="$style.isms__text">IS 766692 / ISO 27001</p>
              </div>
              <p
                :class="[
                  $style.certifications__description,
                  $style.description,
                ]"
              >
                オカネコは、情報セキュリティの強化に取り組んでいます
              </p>
              <div :class="[$style.certifications__pmark, $style.pmark]">
                <a href="https://privacymark.jp/" target="_blank">
                  <img
                    src="./pmark.png"
                    alt="Pマークロゴ"
                    loading="lazy"
                    :class="$style.pmark__image"
                  />
                </a>
              </div>
              <p
                :class="[
                  $style.certifications__description,
                  $style.description,
                ]"
              >
                オカネコは、個人情報を大切に取り扱います
              </p>
            </div>
          </section>
        </div>
      </div>
      <div :class="[$style.footer__company, $style.company]">
        <section :class="[$style.company__signature, $style.signature]">
          <h3>株式会社400F</h3>
          <p>
            <small :class="$style.signature__item">
              金融サービス仲介業者 関東財務局長（金サ）第1号
            </small>
          </p>
          <p>
            <small :class="$style.signature__item">
              加入協会: 一般社団法人日本金融サービス仲介業協会
            </small>
          </p>
          <p>
            <small :class="$style.signature__item">
              Copyright &copy; 400F. All rights reserved.
            </small>
          </p>
        </section>
      </div>
    </footer>
  </div>
</template>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid $color-border-low-emphasis;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 24px 28px;
  background-color: $color-surface-primary;
  max-width: $content-width-large;

  @include desktop-only {
    padding: 64px;
  }

  &__logo {
    height: 72px;

    @include desktop-only {
      height: 100px;
    }
  }

  &__body {
    box-sizing: border-box;
    width: 100%;
    margin-top: 56px;

    @include desktop-only {
      margin-top: 32px;
    }
  }

  &__company {
    margin-top: 64px;

    @include desktop-only {
      margin-top: 40px;
    }
  }
}

.body {
  @include desktop-only {
    display: flex;
    justify-content: space-between;
    gap: 32px;
  }
}

.links {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.section {
  &__title {
    font-size: 18px;
    font-weight: bold;
    color: $color-text-and-object-high-emphasis;
  }

  &__list {
    margin-top: 24px;
    padding: 0;
  }
}

.list {
  list-style: none;
  display: flex;
  flex-direction: column;

  @include desktop-only {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__item {
    line-height: 1;

    &:nth-child(n + 2) {
      margin-top: 16px;

      @include desktop-only {
        margin-top: 0;
      }
    }
  }

  &__link {
    font-size: 14px;
    color: $color-text-and-object-medium-emphasis;
  }
}

.certifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 16px;

  @include desktop-only {
    flex-direction: row;
    align-items: flex-start;
  }
}

.isms {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;

  &__image {
    width: 190px;
    background-color: $color-surface-primary;
  }

  &__text {
    font-size: 10px;
    color: $color-text-and-object-high-emphasis;
  }
}

.pmark {
  background-color: $color-surface-primary;
  flex-shrink: 0;
  width: 64px;
  height: 64px;
}

.description {
  font-size: 14px;
}

.company {
  @include desktop-only {
    margin-right: auto;
  }
}

.signature {
  color: $color-text-and-object-high-emphasis;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include desktop-only {
    align-items: flex-start;
  }

  &__item {
    font-size: 10px;
  }
}
</style>
